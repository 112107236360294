.dashboard-cards > div > .card > .card-body {
  height: 210px;
  overflow: auto;
}
 
.channels-card {
  .custom-badge {
    line-height: 12px;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: -3px;
  }
  .table tr:first-child td {
    border: 0;
  }
}

.news-card {
  article > span {
    display: block;
    margin-bottom: 0.25rem;
    strong {
      font-size: .9rem;
    }
  }

  ul {
    list-style: none;
    padding-left: 0.5rem;
    li{
      padding-left: 0;
      strong {
        font-weight: 600;
      }
    }
    ul {
      padding-left: 0.5rem;
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  img {
    max-width: 100%;
  }
}

.custom-pickers {
  .date-input {
    width: 120px;
  }

  .title, .to {
    font-weight: 600;
  }

  @media (max-width: 425px) {
    .react-datepicker-wrapper, .react-datepicker__input-container {
      width: 100%;
      .date-input {
        width: 100%;
        margin: 0 !important;
      }
    }

    .to {
      color: transparent;
      font-size: 6px;
    }

    .title {
      margin-bottom: 12px;
    }
  }
}

.custom-tooltip {
  background-color: #FFF;
  border-radius: 0.25rem;
  padding: 8px 10px;
  box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
  
  p {
    margin: 2px 0;
  }

  .label {
    font-size: 12px;
    margin-top: 6px;
  }

  .value-0 {
    color: #336db0;
  }
  
  .value-1 {
    color: #27a844;
  }
}

.sample-data {
  .card-body {
    position: relative;
    &:before {
      content: 'SAMPLE DATA';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2em;
      font-weight: 600;
      color: #999;
      background: rgba(0, 0, 0, 0.08);
    }
  }
}

.dashboard-graphs .is-empty {
  margin: 0;
  border-radius: 0.25rem;
  background-color: #FFF;
  padding: 2rem 0 !important;
  box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
  h6 {
    margin-bottom: 0;
  }
}

.onboard-modal {
  .card {

    &.disabled {
      color: #888c9b;
      pointer-events: none;

      svg {
        color: #888c9b;
      }
    }
  }
}