body .notification .alert-dismissible .close {
  padding: 0.6rem 1.25rem;
  margin: auto;
  bottom: 4px;
  top: 0;
}
body .notification p {
  margin-bottom: 0;
  color: #000;
}
body .notification .bullet {
  margin: 0 0.8rem;
}
@media (max-width: 991px) {
  body .notification .bullet {
    display: none;
  }
  body .notification .extra-info {
    margin-top: 0.5rem;
  }
}

