.date-range-picker {
  .date-input {
    max-width: 120px;
  }

  .time-input {
    max-width: 70px;
    background: #fff;
  }

  .react-datepicker__day {
    &--in-range {
      background-color: #216ba5cc;
    }
    &--selected {
      background-color: #216ba5;
    }
  }

  > b {
    &.from, &.to {
      min-width: 35px;
      display: inline-block;
    }
    &.from {
      margin-bottom: 1rem;
      margin-right: .5rem;
    }
    &.at {
      margin: 0 .5rem;
    }
    &.start.at {
      margin-left: 0;
    }
  }

  .to {
    font-weight: 600;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__time-container,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;

    ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      height: 25px;
      padding: 5px;
    }
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 110px;
  }

  @media (max-width: 425px) {
  .react-datepicker-wrapper, .react-datepicker__input-container {
      width: 100%;
      .date-input {
        width: 100%;
        margin: 0 !important;
      }
    }
    
    .to {
      color: transparent;
      font-size: 6px;
    }
  }
}