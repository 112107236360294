// My Bootstrap configuration
// @import "custom_bootstrap";
// The custom boostrap is now on vendor/assets/scss/custom

// Bootstrap library
// @import "bootstrap/scss/bootstrap.scss";
@import "vendor/assets/scss/theme";
// @import "vendor/assets/scss/custom";

// Here you can import more .scss files or write scss code.

body {
  background-color: $sd-grey;

  /* Normalizing sentry components */
  .sentry-error-embed .form-submit {
    .btn {
      color: #fff;
      background-color: #16689f;
      padding: 0.375rem 0.75rem;
      &:hover {
        box-shadow: none;
        background-color: #104b72;
        border-color: #0e4367;
      }
    }
    .close {
      color: $gray-700;
      opacity: 0.75;
      &:hover {
        opacity: 1;
      }
    }
  }

  &:has(.page.template-edit) {
    max-width: none;
  }
}

svg {
  margin-bottom: 0.12rem;
  margin-right: 0.3rem;
  margin-left: 0.1rem;
}
.custom-svg {
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.box-shadow {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}

.sd-blue {
  color: $sd-blue;
}
.sd-blue-bg {
  background-color: $sd-blue;
  color: white;
}

.modal-huge {
  max-width: 1900px;
}
@media (min-width: 576px) {
  .modal-dialog.modal-huge {
    margin: 10px;
  }
}
@media (min-width: 1920px) {
  .modal-dialog.modal-huge {
    margin: 10px auto;
  }
}

.modal-fullscreen {
  max-width: 100%;
}

@media (min-width: 576px) {
  .modal-dialog.modal-fullscreen {
    margin: 10px;
  }
}

// .form-control-sm::placholder {
//   font-size: 12px;
// }

mark,
.mark {
  padding: 0;
}

// @import "components/NavWizard.scss";

// .card-header {
//   background-color: $gray-100;
// }

// .card-footer {
//   padding: 0.5rem 0.5rem 0.7rem 0.5rem;
//   background-color: $gray-100;
// }

// .card-header {
//   background-color: $gray-100;
// }

// .card-footer {
//   padding: 0.5rem 0.5rem 0.7rem 0.5rem;
//   background-color: $gray-100;
// }

@include media-breakpoint-down(xs) {
  // .container-fluid {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  // .card {
  //   border-radius: 0;
  // }
  // .card-header {
  //   padding: 0.5rem 0.5rem 0.7rem 0.5rem;
  // }
  // .card-header-tabs {
  //   padding: 1px;
  // }
  // .card-body {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  // .card-body .card-body {
  //   padding-left: 1.25rem;
  //   padding-right: 1.25rem;
  // }
  // .list-group-item {
  //   padding: 0.75rem 0.5rem;
  // }
  // p {
  //   padding-left: 0.5rem;
  //   padding-right: 0.5rem;
  // }
  // .nav-pills.nav-wizard > li:first-child a {
  //   border-radius: 0;
  // }
  // .nav-pills.nav-wizard > li:last-child a {
  //   border-radius: 0;
  // }
  // .list-group-item:first-child {
  //   border-radius: 0;
  // }
  // .list-group-item:last-child {
  //   border-radius: 0;
  // }
}

// .alert {
//   line-height: 38px;
// }

.alert.alert-danger {
  line-height: inherit;
}

/* Workaround for form valid checkbox position bug https://github.com/JeffreyWay/laravel-mix/issues/2041 */
/* Somehow have to get this not to compile, but translate verbatim, because scss compilation causes the issue. */
.was-validated .form-control:valid,
.form-control.is-valid {
  background-position-y: 50%;
  background-position-x: 96%;
}

/* Fix bug where list group item sides have an extra border */
.list-group-item-flush {
  border-right: none;
  border-left: none;
}

/* Fix copy to clipboard focused but not hovered state */
.copy-clipboard.btn:focus:not(:hover) {
  color: #888c9b;
}

/* Fix padding on Selects fixed label */
.select-with-label .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
  display: flex;
  padding-top: 14px;
  padding-left: 2px;
}

.images-carousel {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow: auto;
  img {
    max-width: 23%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .images-carousel img {
    max-width: 75%;
    margin-right: 3%;
  }
}

/* Down arrow for CardHeader collapse */
.card-header.has-arrow {
  cursor: pointer;
  padding-right: 2rem;
  border-bottom-color: #efefef;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 16px;
    top: 20px;
    border: solid #bbb;
    border-width: 0px 3px 3px 0;
    transform: rotate(45deg);
  }
}

/* Green check icons on <li> */
ul.show-checks {
  list-style: none;

  li {
    position: relative;

    &:before {
      top: 2px;
      content: "";
      width: 14px;
      left: -20px;
      height: 14px;
      position: absolute;
      background-size: 14px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%2300A28A' d='M400 200c0 110.457-89.543 200-200 200S0 310.457 0 200 89.543 0 200 0s200 89.543 200 200'/%3E%3Cpath fill='%23FFF' d='M172.056 312.674l-77.31-80.753c-8.202-8.567-7.906-22.16.66-30.361 8.568-8.202 22.16-7.905 30.361.66l42.349 44.234L272.582 107.53c7.128-9.478 20.59-11.384 30.068-4.256 9.478 7.127 11.384 20.588 4.256 30.068l-134.85 179.332'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.scheduled-event {
  ul {
    margin: 0;
    padding-left: 0;
    li {
      display: flex;
      list-style: none;
      font-weight: 500;
      padding: 0.35rem 0;
      align-items: center;
      word-break: break-all;
    }
  }

  svg {
    fill: currentColor;
  }

  p {
    font-weight: 500;
    b {
      font-weight: 600;
    }
  }
}
.custom-control-label::after {
  background: no-repeat 51% / 63% 63%;
}

.sentry-error-embed-wrapper {
  z-index: 1050 !important;
}

.testimonials {
  justify-content: space-evenly;

  p, 
  footer {
    font-size: .75rem;
  }

  .card {
    background-color: white;
  }

  svg {
    margin: 0;
    max-width: 100%;
  }
}

pre > .hljs {
  padding: 5px 0;
}

.link-outline {
  text-decoration: none;
  border: 1px solid #346CB0;
  
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #346CB0;
  }

  &:focus {
    text-decoration: none;
  }
}

.confirmation-modal {
  .modal-header, .modal-footer {
    box-shadow: none;
  }
}

.Select-menu-outer {
  z-index: 9;
}
