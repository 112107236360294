body .visual-picker {
  .visual-picker-figure:after {
    content: "";
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: cover;
    top: 12px;
    right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23336db0' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'%3E%3C/path%3E%3C/svg%3E");
  }

  &.active {
    .visual-picker-figure {
      box-shadow: inset 0 0 0 2px #346cb0, 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
      &:after {
        opacity: 1;
      }
    }
  }

  &:not(.active) {
    input[type="radio"]:checked ~ .visual-picker-figure {
      box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
      &:after {
        opacity: 0;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    &:hover {
      .visual-picker-figure {
        box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
      }
    }
  }
}