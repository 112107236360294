
.permissions-card {
  .custom-checkbox .custom-control-label{
    &:before, &:after {
      left: 0;
      right: 0;
      margin: 20px auto 0;
    }
  }

  .field:last-child hr {
    display: none;
  }

  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(22, 104, 159, 0.2);
  }
}