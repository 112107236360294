.qualifications {
  p, input, legend {
    margin-bottom: .75rem;
  }

  .channels-list-wrapper {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    label.visual-picker-figure {
      margin: 0;
      width: auto;
      height: 40px;
      padding: 0 20px;
  
      .visual-picker-content {
        font-size: .9rem;
        text-transform: capitalize;
  
        svg {
          width: 16px;
          height: 16px;
        }
      }
  
      &:after {
        top: 3px;
        right: 3px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        transition: .2s ease;
        transform: scale(1.05);
      }
    }
  }

  button.continue {
    &:not(.disabled) {
      &:hover {
        svg {
          transition: .2s ease;
          transform: translate(5px, 0)
        }
      }
    }
  }

  .Select-menu {
    max-height: 130px;
  }
}