#progressBarContainer {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    position: fixed;
}

#progressBar {
    height: 6px;
    transform: scale(0,0);
    transform-origin: top left; 
    background: linear-gradient(to left, #4ca4d8, #30619f);
}
