.dashboard-cards > div > .card > .card-body {
  height: 210px;
  overflow: auto;
}

.channels-card .custom-badge {
  line-height: 12px;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: -3px;
}
.channels-card .table tr:first-child td {
  border: 0;
}

.news-card article > span {
  display: block;
  margin-bottom: 0.25rem;
}
.news-card article > span strong {
  font-size: 0.9rem;
}
.news-card ul {
  list-style: none;
  padding-left: 0.5rem;
}
.news-card ul li {
  padding-left: 0;
}
.news-card ul li strong {
  font-weight: 600;
}
.news-card ul ul {
  padding-left: 0.5rem;
}
.news-card ul ul li {
  margin-bottom: 0.5rem;
}
.news-card img {
  max-width: 100%;
}

.custom-pickers .date-input {
  width: 120px;
}
.custom-pickers .title, .custom-pickers .to {
  font-weight: 600;
}
@media (max-width: 425px) {
  .custom-pickers .react-datepicker-wrapper, .custom-pickers .react-datepicker__input-container {
    width: 100%;
  }
  .custom-pickers .react-datepicker-wrapper .date-input, .custom-pickers .react-datepicker__input-container .date-input {
    width: 100%;
    margin: 0 !important;
  }
  .custom-pickers .to {
    color: transparent;
    font-size: 6px;
  }
  .custom-pickers .title {
    margin-bottom: 12px;
  }
}

.custom-tooltip {
  background-color: #FFF;
  border-radius: 0.25rem;
  padding: 8px 10px;
  box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
}
.custom-tooltip p {
  margin: 2px 0;
}
.custom-tooltip .label {
  font-size: 12px;
  margin-top: 6px;
}
.custom-tooltip .value-0 {
  color: #336db0;
}
.custom-tooltip .value-1 {
  color: #27a844;
}

.sample-data .card-body {
  position: relative;
}
.sample-data .card-body:before {
  content: "SAMPLE DATA";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 600;
  color: #999;
  background: rgba(0, 0, 0, 0.08);
}

.dashboard-graphs .is-empty {
  margin: 0;
  border-radius: 0.25rem;
  background-color: #FFF;
  padding: 2rem 0 !important;
  box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
}
.dashboard-graphs .is-empty h6 {
  margin-bottom: 0;
}

.onboard-modal .card.disabled {
  color: #888c9b;
  pointer-events: none;
}
.onboard-modal .card.disabled svg {
  color: #888c9b;
}

