.channel-errors {
  .errors-list {
    height: 500px;
    overflow: auto;
    margin-bottom: 1.5rem;

    .list-group-item {
      top: 0;
      position: sticky;

      & > span {
        display: none;
      }

      .form-group {
        margin: 0;
      }
    }

    .media {
      width: 50px;
      height: 50px;
      display: flex;
      min-width: 50px;
      align-items: center;
      border-radius: 0.25rem;
      justify-content: center;
      outline: rgb(227, 227, 227) solid 1px;
    
      img {
        width: 100%;
      }
    }

    .title, .identifier {
      width: 20%;
      color: inherit;
    }

    .result {
      width: 80px;
    }
    
    .message {
      width: 60%;
    }

    .view-log {
      width: auto;
      overflow: hidden;
      min-width: 100px;
    }
  }

  .days-ago-select {
    width: 120px;
    cursor: pointer;
  }

  .actions {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .left, .right {
      gap: 0.5rem;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 991px) {
  .channel-errors {
    .title {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .channel-errors {
    .actions {
      .Select, button, a, .left, .right {
        width: 100%;
      }
    }
  }
}
