// put your own variables to customize and override the theme variables

$sd-blue: #16689f;
$sd-light-blue: #549bd8;
$sd-grey: #eee;
$sd-flag-orange: rgb(238, 93, 50);
$sd-red-dot: rgb(238, 32, 35);

// $enable-rounded: false;

// imported
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$enable-responsive-font-sizes: true;
// $enable-gradients: true;
// $enable-shadows: true;
// $font-size-base: 0.9rem;

// overwite
$theme-colors: (
  primary: $sd-blue,
  primaryAlt: $sd-light-blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

// $border-radius:          1.2rem !default;
// $border-radius-lg:       1.5rem !default;
// $border-radius-sm:       1rem !default;
