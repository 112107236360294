body.hide-intercom-launcher {
  #go-to-top, #go-to-bottom {
    opacity: 0;
    padding: 0;
    right: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    position: fixed;
    align-items: center;
    background: #336cb0;
    transition: .2s ease;
    border-radius: 100px;
    transform: scale(0.5);
    border-color: #336cb0;
    justify-content: center;
    
    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

  #go-to-top {
    bottom: 70px;
  }

  #go-to-bottom {
    bottom: 25px;
  }
  
  .intercom-lightweight-app {
    display: none;
  }
}