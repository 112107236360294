.logs-list, .specific-log {
  .Select-menu-outer, .Select-menu {
    // max-height: none;
  }

  .Select {
    z-index: 1;
    width: 100%;
    cursor: pointer;

    &.filter-by-select {
      z-index: 2;
      min-width: 150px;
    }
  }

  .expander {
    display: none;
  }
  
  .magnifier {
    padding: 0;
    width: 24px;
    height: 24px;
    border: none;
    margin-top: -1px;
    margin-left: 1px;
    line-height: 10px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background: transparent;

    svg {
      width: 100%;
    }

    &:not(:focus) {
      box-shadow: none;
    }
  }

  th:first-child {
    width: 50px;
  }

  .sub-table {
    tr:first-child {
      th, td {
        border-top: 1px solid #ccc;
      }
    }
  }

  .sd-pill {
    .add-btn {
      height: 12px;
    }
  }

}

.specific-log {
  .limit-height {
    max-height: 600px;
  }

  pre {
    margin: 0;
    max-height: 800px;
  }

  code {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .col {
    &-key {
      width: 10rem;
      display: flex;
      align-items: flex-start;
    }
    
    &-data {
      display: flex;
      align-items: center;
      width: calc(100% - 19rem);
    }
    
    &-copy {
      width: 9rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 991px) {
  .logs-list {
    .date-range-picker {
      width: 100%;
      text-align: right; 
    }
  }
}

@media (max-width: 768px) {
  .logs-list {
    table {
      th, td {
        padding: 0.25rem;
      }
    }
  }
}