.initial-search {
  form {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .select-wrapper {
      width: 100%;

      label {
        font-weight: 600;
      }
    }
  }

  .is-empty {
    opacity: .7;
    margin-top: 48px;
  }

  .Select-placeholder .spinner {
    display: flex;
    margin: 10px auto;
  }

  .ellipsis-loading {
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      animation: dotty 2s infinite;
    }
  }

  .no-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 40px;

    p {
      margin: 0;
      width: 100%;
      margin: 5px 0px;
      font-weight: 500;
    }

    button {
      margin-top: -4px;
    }
  }

  @keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
  }
}

.fitment-results {
  .menu-item > legend:first-letter {
    text-transform: uppercase;
  }

  .menu.group {
    max-height: 370px;
    overflow: auto !important;
  }

  .product {
    color: inherit;
    text-decoration: none;
    
    &.card {
      transition: .1s ease;

      .name {
        height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }

    &:hover {
      .card {
        transform: scale(1.005);
        box-shadow: 0 1px 3px 0 #14141f26, 0 0.5rem 1rem #14141f26;
      }
    }
  }
}

pre > code {
  padding: 10px !important;
}

.popover.show {
  width: auto;
}

.fitment-settings {
  button#PopoverFocus {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
  }
  button.btn.btn-outline-secondary {
    &:focus, &:hover {
      color: #656565;
    }
  }
  ol > li {
    padding-top: 10px;
  }
  ul {
    padding-bottom: 5px;
  }
  pre {
    margin: 0;
  }
  ul.pagination {
    margin: 0;
    padding: 0;
  }
  .page-section {
    margin-bottom: 4rem;
  }
  .check-disabled {
    background-color: #f6f7f9;
    pointer-events  : none !important;
    
    .custom-control-label {
      pointer-events: none !important;
    }
    
    .custom-control-label {
      &:before, &:after {
        opacity: 0.75;
        pointer-events: none !important;
      }
    }
  }
}

.card-header-tabs {
  padding: 1rem 0.5rem;

  &.nav-tabs {
    padding: 0;
  }
}

$primary-color: #0a679d;
.rc-slider {
  &-track {
    background-color: rgba($primary-color, 0.7);
  }

  &-handle {
    border: solid 2px rgba($primary-color, 0.6);

    &-dragging {
      border-color: rgba($primary-color, 0.3) !important;
      box-shadow: 0 0 0 5px rgba($primary-color, 0.5) !important;
    }

    &-click-focused:focus {
      border-color: rgba($primary-color, 0.6);
    }
  
    &:hover {
      border-color: rgba($primary-color, 0.3);
    }
  
    &:active {
      border-color: rgba($primary-color, 0.3);
      box-shadow: 0 0 5px rgba($primary-color, 0.3);
    }
  }
}

@media (min-width: 768px) {
  .fitment-results {
    padding-left: 15rem;
  }
}

@media (max-width: 576px) {
  .initial-search {
    form {
      flex-wrap: wrap;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .5rem;
      }
    }
  }
}