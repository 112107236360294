.channel-management li .Select-control {
  cursor: pointer;
}
.channel-management li .Select-menu-outer {
  z-index: 2;
}
.channel-management li:hover {
  z-index: unset;
}

