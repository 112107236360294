@import "custom_bootstrap";

.sidebar .modal-dialog {
  position: "absolute";
  margin-top: 0;
  margin-left: 0;
  height: 100%;
}

.sidebar-section {
  svg {
    color: $gray-600 !important;
  }
  .active {
    svg {
      color: $sd-blue !important;
    }
  }
}

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: transparent !important;
    color: $sd-blue !important;
    font-weight: bold !important;
  }
}

.sidebar-section {
  svg {
    color: $gray-600 !important;
  }
  .active {
    svg {
      color: $sd-blue !important;
    }
  }
}

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: transparent !important;
    color: $sd-blue !important;
    font-weight: bold !important;
  }
}
