.user-summary svg {
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}
.user-summary .btn-secondary {
  color: #363642;
  background: linear-gradient(to bottom, #ffffff, #f6f7f9);
  border-color: #d7dce5;
  box-shadow: 0 1px 0 0 rgba(20, 20, 31, 0.05);
}
.user-summary .btn-secondary:hover {
  background: linear-gradient(to bottom, #f6f7f9, #f6f7f9);
  border-color: #d7dce5;
}
.user-summary .btn-secondary:focus,
.user-summary .btn-secondary.focus {
  background: linear-gradient(to bottom, #ffffff, #f6f7f9);
  border-color: #346cb0;
  box-shadow: 0 0 0 1px #346cb0;
}
.user-summary .btn-secondary.disabled,
.user-summary .btn-secondary:disabled {
  background: linear-gradient(to bottom, #f6f7f9, #f6f7f9);
  border-color: #d7dce5;
}
.user-summary .btn-secondary:not(:disabled):not(.disabled):active,
.user-summary .btn-secondary:not(:disabled):not(.disabled).active,
.user-summary .show > .btn-secondary.dropdown-toggle {
  background: linear-gradient(to bottom, #ffffff, #f6f7f9);
  box-shadow: inset 0 1px 1px 0 rgba(20, 20, 31, 0.1), inset 0 1px 4px 0 rgba(20, 20, 31, 0.2);
  border-color: #d7dce5;
}
.user-summary .btn-secondary:not(:disabled):not(.disabled):active:focus,
.user-summary .btn-secondary:not(:disabled):not(.disabled).active:focus,
.user-summary .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 1px 1px 0 rgba(20, 20, 31, 0.1), inset 0 1px 4px 0 rgba(20, 20, 31, 0.2);
}

.list-group-item {
  cursor: pointer;
}

