body .channel-detail .Select-placeholder,
body .channel-detail .custom-control label {
  cursor: pointer;
  z-index: 2;
}

.resource-list.imports {
  box-shadow: none;
}
.resource-list.imports li {
  border: none;
}

.import-summary .preview-label {
  font-weight: 500;
}

.import-type-title {
  font-size: 15px;
  font-weight: 600;
}

.type-of-import .visual-picker {
  height: 100%;
  margin: 0 !important;
}
.type-of-import .visual-picker .visual-picker-figure {
  align-items: flex-start;
}
.type-of-import .visual-picker .visual-picker-figure h6 {
  font-size: 14px;
  font-weight: 500;
}

.import-fields .custom-checkbox.custom-control {
  min-height: unset;
}
.import-fields .disabled-field .custom-control-label::before {
  pointer-events: none !important;
}

