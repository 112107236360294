.main-navbar {
  .main-searchbox {
    .top-bar-search {
      .input-group-search {
        .input-group-apend {
          .content {
            border-radius: 3px;
            border: 1px solid #a5bbda;
          }
        }

        &:has(input:focus) {
          background-color: #fff;

          input {
            color: #363642;
          }

          svg, input::placeholder {
            color: rgb(54 54 66 / 50%);
          }

          .input-group-apend {
            display: none;
          }
        }
      }

      .dropdown-menu {
        width: 100%;
        margin-top: 10px;
        min-width: 500px;

        .options-wrapper {
          overflow: auto;
          max-height: 500px;
        }

        &.show {
          opacity: 1 !important;
          pointer-events: auto !important;
        }

        .dropdown-header {
          color: #363642;
          border-bottom: 1px solid #ededef;
        }

        .dropdown-item {
          display: flex;
          min-height: 48px;
          align-items: center;
          padding: 0.25rem 1rem;
          justify-content: space-between;
          border-bottom: 1px solid #ededef;

          &:hover, &:focus, &.active {
            color: #fff;

            .text-muted {
              color: #fff !important;
            }
          }
        }

        .options-footer {
          display: flex;
          padding: 0.5rem 1rem;
          margin-bottom: -0.5rem;
          background-color: #f6f7f9;
          justify-content: space-between;
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;

          span, a {
            opacity: .75;
            cursor: pointer;
            color: #363642;
            text-decoration: none;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

}