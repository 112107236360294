main.fitment-advanced-instructions {
  * {
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-size: revert;
    font-weight: bold;
  }

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  ol {
    margin-bottom: 0;
  }

  li {
    padding: 5px 0;
  }

  pre {
    margin-bottom: 0;
  }

  img {
    height: auto;
    max-width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
  }

  .instruction-collapse {
    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe.intercom-launcher-frame {
    display: none;
  }
}