.user-summary {
  svg {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  //On looper this is loaded from dist/assets/stylesheets/themes.css
  .btn-secondary {
    color: #363642;
    background: linear-gradient(to bottom, #ffffff, #f6f7f9);
    border-color: #d7dce5;
    box-shadow: 0 1px 0 0 rgba(20, 20, 31, 0.05);
  }

  .btn-secondary:hover {
    background: linear-gradient(to bottom, #f6f7f9, #f6f7f9);
    border-color: #d7dce5;
  }

  .btn-secondary:focus,
  .btn-secondary.focus {
    background: linear-gradient(to bottom, #ffffff, #f6f7f9);
    border-color: #346cb0;
    box-shadow: 0 0 0 1px #346cb0;
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    background: linear-gradient(to bottom, #f6f7f9, #f6f7f9);
    border-color: #d7dce5;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background: linear-gradient(to bottom, #ffffff, #f6f7f9);
    box-shadow: inset 0 1px 1px 0 rgba(20, 20, 31, 0.1), inset 0 1px 4px 0 rgba(20, 20, 31, 0.2);
    border-color: #d7dce5;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 1px 1px 0 rgba(20, 20, 31, 0.1), inset 0 1px 4px 0 rgba(20, 20, 31, 0.2);
  }
}

.list-group-item {
  cursor: pointer;
}
