@import "../../custom_bootstrap";

.auth-header {
  // background-color: $gray-400;
  // color: $gray-700;
  // font-weight: bold;
  background-image: url("../../img/img-1.png");
  a {
    // color: $sd-blue;
  }
}
