main.product-updates {
  h1,h2,h3,h4,h5,h6 {
    font-size: revert;
  }

  img {
    height: auto;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-bottom: 20px;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
  }

  .card {
    transition: box-shadow .2s ease;
    border-radius: 0.25rem;
    &:hover {
      box-shadow: 0 0 12px 0 rgba(20, 20, 31, 0.15);
    }
  }

  .card-body {
    ul {
      padding-left: 1rem;
      ul {
        margin-bottom: 1rem;
      }
    }
    img {
      max-width: 100%;
    }
  }
}
