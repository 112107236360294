.dropdown-aside {
  &.dropdown-aside-custom {
    transform: translate3d(0px, 64px, 0px) !important;
  }
}

.dropdown-arrow, .dropdown-arrow:before {
    display: block;
    position: absolute;
    left: .5rem;
    pointer-events: none;
}

.dropdown-arrow {
    top: 0;
    overflow: hidden;
    height: 1.25rem;
    width: 1.25rem;
    z-index: 1001;
    transform: translate3d(0,-100%,0);
}
