.sidebar .modal-dialog {
  position: "absolute";
  margin-top: 0;
  margin-left: 0;
  height: 100%;
}

.sidebar-section svg {
  color: #868e96 !important;
}
.sidebar-section .active svg {
  color: #16689F !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #16689F !important;
  font-weight: bold !important;
}

.sidebar-section svg {
  color: #868e96 !important;
}
.sidebar-section .active svg {
  color: #16689F !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #16689F !important;
  font-weight: bold !important;
}

