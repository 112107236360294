.channel-toggle.custom-switch {
  padding-left: 2.25rem;
  padding-bottom: 0rem;
}
.channel-toggle.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}
.channel-toggle.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 1.6rem;
  width: 3.2rem;
  pointer-events: all;
  border-radius: 0.8rem;
}
.channel-toggle.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1.6rem - 4px);
  height: calc(1.6rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.8rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
.channel-toggle .custom-control-label {
  cursor: pointer;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.1rem;
}

