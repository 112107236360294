.automation-summary {
  a {
    &.user-avatar {
      &.user-avatar-md {
        width: 15rem;
        height: 5rem;
        img {
          border-radius: 0;
          object-fit: contain;
        }
      }
    }
  }
}
