body .notification {
  .alert-dismissible .close {
    padding: 0.6rem 1.25rem;
    margin: auto;
    bottom: 4px;
    top: 0;
  }

  p {
    margin-bottom: 0;
    color: #000;
  }

  .bullet {
    margin: 0 .8rem;
  }

  @media (max-width: 991px) {
    .bullet {
      display: none;
    }

    .extra-info {
      margin-top: .5rem;
    }
  }
  
}