.resource-list .list-group-item.inactive {
  color: #868e96;
  background-color: #e9ecef;
}

.resource-list .list-group-item.disabled {
  color: #868e96;
  background-color: #e9ecef;
}

ul.resource-list.automations-installed-list.list-group {
  box-shadow: none;
}

