body .channel-detail {
  .Select-placeholder,
  .custom-control label {
    cursor: pointer;
    z-index: 2;
  }
}

.resource-list.imports {
  box-shadow: none;
  
  li {
    border: none;
  }
}

.import-summary {
  .preview-label {
    font-weight: 500;
  }
}

.import-type-title {
  font-size: 15px;
  font-weight: 600;
}

.type-of-import {
  .visual-picker {
    height: 100%;
    margin: 0 !important;
    
    .visual-picker-figure {
      align-items: flex-start;
      
      h6 {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.import-fields {
  .custom-checkbox.custom-control {
    min-height: unset;
  }

  .disabled-field {
    .custom-control-label::before {
      pointer-events: none !important;
    }
  }
}
