.search-docs {
  h2 { font-size: 1.5rem }
  h3 { font-size: 1rem }
  h4 { font-size: .75rem }

  img {
    max-width: 60%;
    display: block;
    border-radius: 4px;
    margin: 0 auto 1rem;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
  }

  .resource-list-header {
    font-size: 1rem;
    font-weight: 600;
    min-height: 52px;
    margin-bottom: 4px;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);

    .col {
      display: flex;
      align-items: center;
    }

    .Select {
      width: 105px;
      cursor: pointer;
    }
  }

  .resource-list {
    input {
      width: 30%;
      height: 30px;
    }

    & > li {
      .form-group {
        margin: 0;
      }

      & > span {
        display: none;
      }
    }
  }
}