.custom.auth-form.contact {
  ul {
    list-style: none;
  }
}

body .custom-control:not(.custom-switch) .custom-control-label {
  &::before, &::after {
    top: 2px;
  }
}
