.search-builder {
  .last-searches, .saved-searches, .useful-searches {
    overflow: auto;
    max-height: 155px;

    .sd-pill {
      transition: .2s ease;
      cursor: pointer !important;
  
      &:hover {
        border-color: #363642;
      }
    }
  }

  .search-preview {
    gap: .5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sd-pill {
      height: 29px;
      display: flex;
      align-items: center;
    }
  }
}

@media (min-width: 991px) {
  .search-builder {
    .filter-by {
      & > div {
        padding: 0 .25rem;
       
        &:first-child {
          padding-left: 0.5rem;
        }
  
        &:last-child {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
