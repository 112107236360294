.page-cover .cover-controls .alert {
  top: 70px;
  right: 1.5rem;
  z-index: 9999;
  margin: 0 auto;
  position: fixed;
  width: calc(100% - 18rem);
}

@media (max-width: 768px) {
  .page-cover .cover-controls .alert {
    width: 96%;
    right: 1rem;
  }
}