@import 'custom_bootstrap';

.resource-list .list-group-item.inactive {
  color: $gray-600;
  background-color: $gray-200;
}

.resource-list .list-group-item.disabled {
  color: $gray-600;
  background-color: $gray-200;
}

ul.resource-list.automations-installed-list.list-group {
  box-shadow: none;
}