main.product-updates h1, main.product-updates h2, main.product-updates h3, main.product-updates h4, main.product-updates h5, main.product-updates h6 {
  font-size: revert;
}
main.product-updates img {
  height: auto;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15);
}
main.product-updates .card {
  transition: box-shadow 0.2s ease;
  border-radius: 0.25rem;
}
main.product-updates .card:hover {
  box-shadow: 0 0 12px 0 rgba(20, 20, 31, 0.15);
}
main.product-updates .card-body ul {
  padding-left: 1rem;
}
main.product-updates .card-body ul ul {
  margin-bottom: 1rem;
}
main.product-updates .card-body img {
  max-width: 100%;
}

