.permissions-card .custom-checkbox .custom-control-label:before, .permissions-card .custom-checkbox .custom-control-label:after {
  left: 0;
  right: 0;
  margin: 20px auto 0;
}
.permissions-card .field:last-child hr {
  display: none;
}
.permissions-card .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 104, 159, 0.2);
}

