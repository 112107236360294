.custom.auth-form.terms-page {
  .initial {
    p {
      font-size: 16px;
    }
  }

  .terms {
    ol {
      padding-left: 15px;
      span {
        display: block;
        font-weight: bold;
        margin: 25px 0 5px;
      }
    }

    li {
      margin: 5px 0;
    }
  }
}