.sd-pill {

  &.badge {
    width: auto;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #c2c5cc80;
  }

  .close-btn, .add-btn {
    padding: 0 6px 0 0;
    font-size: 18px;
    color: #b5b6ba;
    align-items: center;
    display: inline-flex;
    margin: -2px 0 0 0;
    
    &:hover, &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  .close-btn {
    max-height: 15px;
  }
}
