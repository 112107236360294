@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.page.channel-detail header{

  .title-description-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
  }

  .custom-title {
    position: relative;
    display: block;
    > .badge {
      position: absolute;
      top: -8px;
      right: -30px;
      font-size: 12px;
    }
  }

  .page-description {
    margin-left: 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .content-wrapper {
      display: flex;
    }
  }

  @media (max-width: 576px) {
    span.custom-title svg {
      margin: 0;
    }
    .content-wrapper {
      margin-top: 10px;
    }
    
    .page-description {
      width: 100%;
      margin: 0;
    }
  }
}

.channel-settings .Select-menu {
  max-height: 150px;
}

.channel-settings .setting-Order-Import-Shipping-Carrier-Mapping {
  display: none;
}

.max-80 {
  max-width: clamp(350px, 80%, 750px);
  margin: 0 auto;
}

.tabbed-field-mappings {
  margin-top: -1rem;

  ul.nav.nav-tabs {
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 0 1px #14141f0d, 0 1px 3px 0 #14141f26;
  }

  .additional-filter-wrapper {
    display: flex;
    align-items: baseline;
  }

  .async-select-wrapper {
    width: clamp(100px, 100%, 350px);
  }
}

body .popover {
  z-index: 1;
  max-width: none;
  width: clamp(100px, 100%, 400px);

  .popover-header {
    font-size: 15px;
  }

  .popover-body {
    font-size: 14px;
  }
}

#authorize-channel-button {
  position: relative;

  // Google button styles
  &.google-auth {
    height: 42px;
    display: flex;
    color: #fff;
    border-radius: 2px;
    padding-left: 54px;
    align-items: center;
    font-family: 'Roboto';
    background: #4285f4;
    border-color: #4385f4;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);

    .icon-wrapper {
      top: 0px;
      left: 0px;
      width: 40px;
      height: 40px;
      display: flex;
      position: absolute;
      border-radius: 2px;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }

    .google-icon svg {
      margin: 0;
      width: 18px;
      height: 18px;
    }

    &:active {
      background-color: #1669F2;
    }

    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
  }

  // Facebook button styles
  &.facebook-auth {
    height: 40px;
    display: flex;
    color: #fff;
    border-radius: 5px;
    align-items: center;
    font-family: 'Roboto';
    background: #4267b2;
    border-color: #4267b2;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);

    .icon-wrapper {
      margin: 0 12px 0 0;
    }

    &:active {
      background-color: #355492;
    }

    &:hover {
      box-shadow: 0 0 6px #4267b2;
    }
  }

  // Amazon button styles
  &.amazon-auth {
    height: 40px;
    display: flex;
    color: #000;
    border-radius: 5px;
    align-items: center;
    font-family: 'Roboto';
    border-color: #b38b23;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    background: linear-gradient(180deg, #ffe8a8, #f6c747);

    .icon-wrapper {
      margin: 0 8px 0 0;
    }

    &:active, &:hover {
      opacity: 0.9;
    }
  }
}
