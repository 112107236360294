.channel-management {
  li {
    .Select-control {
      cursor: pointer;
    }
    .Select-menu-outer{
      z-index: 2;
    }
    &:hover {
      z-index: unset;
    }
  }
}
