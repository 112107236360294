.async-select-wrapper {
  position: relative;

  & > input {
    border-color: #b3b3b3 #ccc #d9d9d9;
  }

  & > ul {
    left: 0;
    top: 100%;
    padding: 0;
    z-index: 1;
    width: 100%;
    overflow: auto;
    list-style: none;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top-color: #e6e6e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 0 #0000000f;

    li {
      padding: 8px 10px;
      cursor: pointer;

      &:hover {
        background-color: #ebf5ff;
      }

      &.disabled {
        color: #999999;
        pointer-events: none;
      }
    }
  }

  .loader {
    top: 10px;
    right: 10px;
    position: absolute;
  }

  &:not(.show-options) {
    & > ul {
      display: none;
    }
  }

  &.hide-focus {
    input {
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: #b3b3b3 #ccc #d9d9d9;
      }
    }
  }
}